AFRAME.registerComponent("simple-cable", {
    schema: {
      start: { type: "vec3" }, // Start point of the cable
      end: { type: "vec3" }, // End point of the cable
      color: { default: "#00ff00" }, // Color of the electricity
      flowSpeed: { default: 0.2 }, // Speed of electricity flow
      thickness: { default: 0.01 }, // Thickness of the cable
    },
    init: function () {
      // Create uniforms for the shader
      const uniforms = {
        time: { value: 0 },
        flowDirection: { value: new THREE.Vector2(0, 1) },
        flowSpeed: { value: this.data.flowSpeed },
        opacity: { value: 0.8 },
        stripeWidth: { value: 0.1 }, // Adjust stripe width as needed
      };
  
      // Vertex shader
      const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `;
      // Fragment shader for yellow-black zebra stripes
      const fragmentShader = `
  uniform float time;
  uniform vec2 flowDirection;
  uniform float flowSpeed;
  uniform float stripeWidth; // Width of each stripe
  varying vec2 vUv;
  uniform float opacity; 
  
  void main() {
    // Calculate offset based on time, direction, and speed (unchanged)
    vec2 offset = vUv + flowDirection * time * flowSpeed;
  
    // Calculate stripe pattern based on UV coordinates and time
    float stripePosition = mod(offset.x + time * flowSpeed, stripeWidth * 2.0);
    float stripe = step(stripeWidth, stripePosition) - step(stripeWidth * 2.0, stripePosition);
  
    // Set yellow and black colors based on stripe pattern
    vec3 yellowColor = vec3(1.0, 1.0, 0.0); // Yellow color
    vec3 blackColor = vec3(0.5, 0.5, 0.0); // Black color
    vec3 color = mix(blackColor, yellowColor, stripe); // Mix colors based on stripe pattern
  
    // Output the color with opacity
    gl_FragColor = vec4(color, opacity);
  }
  `;
  
      // Define control points for the Catmull-Rom curve
      const controlPoints = [
        new THREE.Vector3(
          this.data.start.x,
          this.data.start.y,
          this.data.start.z
        ),
        new THREE.Vector3(this.data.end.x, this.data.end.y, this.data.end.z),
      ];
  
      // Create Catmull-Rom curve
      const curve = new THREE.CatmullRomCurve3(controlPoints);
  
      // Generate points along the curve for cable geometry
      const points = curve.getPoints(50); // Adjust resolution as needed
  
      // Create cable geometry based on points and adjust thickness
      const radius = this.data.thickness; // Adjust the radius to change thickness
      const curveGeometry = new THREE.TubeGeometry(curve, 50, radius, 8, false); // Use TubeGeometry for variable thickness
  
      // Create shader material
      const material = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        side: THREE.DoubleSide, // Ensure both sides of the mesh are visible
      });
  
      // Create the mesh and add it to the scene
      this.mesh = new THREE.Mesh(curveGeometry, material);
      this.el.setObject3D("mesh", this.mesh);
  
      // Start animating the electricity flow
      this.el.sceneEl.addEventListener("tick", this.tick.bind(this));
    },
  
    tick: function (time, deltaTime) {
      // Update time uniform for shader animation
      this.mesh.material.uniforms.time.value +=
        (deltaTime / 1000) * this.data.flowSpeed;
    },
  });
  